@import "tailwind.scss";

* {
    font-family: "DM Sans", sans-serif;
    transition: 0.1s;
}

h1,
h2,
h3,
h4,
h5,
div,
p {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    height: 100vh;
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
